import React from "react"
import { Link } from "gatsby"

const MenuItemsLandingUpdate = ({ isApply }) => {
  return (
    <>
      {isApply
        ?
        <div className="hidden xl:flex items-center space-x-10 mr-4">
          <Link to="/representative-testimonials/apply">
            <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover font-display font-semibold text-base md:text-lg text-white uppercase tracking-wider px-6 py-2">
              Apply Now
            </button>
          </Link>
        </div>
        :
        <div className="hidden xl:flex items-center space-x-10 mr-4">
          <Link to="/qualify">
            <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover font-display font-semibold text-base md:text-lg text-white uppercase tracking-wider px-6 py-2">
              Begin Assessment
            </button>
          </Link>
        </div>
      }
    </>
  )
}

export default MenuItemsLandingUpdate
